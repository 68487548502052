/**
 * 微信js-sdk
 * 参考文档：https://mp.weixin.qq.com/wiki?t=resource/res_main&id=mp1421141115
 */
import wx from 'weixin-js-sdk'
import { getSignature } from "@/api/index"
const wxApi = {
    /**
    * [wxRegister 微信Api初始化]
    * @param  {Function} callback [ready回调函数]
    */
    wxRegister(callback) {
        let url = window.location.protocol + '//' + window.location.host + '/';
        getSignature({ signContent: url }).then((res) => {
            if (res.code == 0) {
                wx.config({
                    debug: false, // 开启调试模式
                    appId: res.data.appId, // 必填，公众号的唯一标识
                    timestamp: res.data.timestamp, // 必填，生成签名的时间戳
                    nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
                    signature: res.data.signature, // 必填，签名，见附录1 
                    jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'scanQRCode'],
                    // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                })
            }
        }).catch((error) => {
            console.log(error)
        })
        wx.ready((res) => {
            // 如果需要定制ready回调方法
            if (callback) {
                callback()
            }
        })
    },

    ScanQRCode(option) {
        wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
                var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                option.success(result)
            },
            fail: function (err) {
                option.error(err)
            }
        });
    },

    ShareTimeline(option) {
        wx.onMenuShareTimeline({  // updateTimelineShareData
            title: option.title, // 分享标题
            link: option.link, // 分享链接
            imgUrl: option.imgUrl, // 分享图标
            success() {
                // 用户成功分享后执行的回调函数
                option.success()
            },
            cancel() {
                // 用户取消分享后执行的回调函数
                option.error()
            }
        })
    },

    ShareAppMessage(option) {
        wx.onMenuShareAppMessage({  // updateAppMessageShareData
            title: option.title, // 分享标题
            desc: option.desc, // 分享描述
            link: option.link, // 分享链接
            imgUrl: option.imgUrl, // 分享图标
            success() {
                // 用户成功分享后执行的回调函数
                option.success()
            },
            cancel() {
                // 用户取消分享后执行的回调函数
                option.error()
            }
        })
    }
}

export default wxApi