<template>
  <div class="main">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="bgBox">
        <div class="msgBox">
          <div class="topBox">
            <span class="leftBox">员工：{{ userMsg.realname }}</span>
            <img src="@/assets/images/out.png" alt="" @click="handleOutLogin" />
          </div>
          <div class="botBox">
            <span class="leftBox">门店：{{ userMsg.shopName }}</span>
            <span class="rBox" @click="handleChangePassword">修改密码?</span>
          </div>
        </div>
      </div>

      <div class="opeBox">
        <div class="topCancel">
          <div
            class="cancelBox"
            id="scanCode"
            ref="scanCode"
            @click="handleScan"
          >
            <img src="@/assets/images/scan.png" alt="" />
            <div>扫码核销</div>
          </div>
          <div class="cancelBox" @click="handleJumpTicketVerify">
            <img src="@/assets/images/verify.png" alt="" />
            <div>卡券号核销</div>
          </div>
        </div>
        <div class="botCancel" @click="handleHistory">
          <img src="@/assets/images/coupon.png" alt="" />
          <span>核销历史查看</span>
        </div>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import wxApi from "@/utils/wechatJSSDK";
import globalStyle from "@/utils/style";
import { logout } from "@/api/index";
import { Notify, Dialog, PullRefresh } from "vant";
import { removeToken, removeRefreshToken } from "@/utils/auth";
export default {
  components: {
    vanPullRefresh: PullRefresh,
  },
  data() {
    return {
      userMsg: "",
      isWx: false,
      isLoading: false,
    };
  },

  created() {
    this.userMsg = localStorage.getItem("userMsg")
      ? JSON.parse(localStorage.getItem("userMsg"))
      : "";
    let ua = window.navigator.userAgent.toLowerCase();
    if (
      ua.match(/MicroMessenger/i) == "micromessenger" &&
      ua.match(/wxwork/i) != "wxwork"
    ) {
      //微信环境
      this.isWx = true;
      wxApi.wxRegister();
    } else {
      this.isWx = false;
    }
  },

  methods: {
    // 下拉刷新
    onRefresh() {
      this.$store
        .dispatch("user/getUserInfo")
        .then((res) => {
          this.userMsg = res.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    // 点击扫码
    handleScan() {
      if (this.isWx) {
        wxApi.ScanQRCode({
          success: (res) => {
            this.$router.push({
              path: "/confirmVerify",
              query: {
                couponNum: res,
                type: 1, //1扫码，2券码
              },
            });
          },
          error: (err) => {
            Notify({
              type: "danger",
              message: "扫码失败，请重试！",
            });
          },
        });
      } else {
        Notify({
          type: "warning",
          message: "扫码功能仅支持在微信内部使用！",
        });
      }
    },

    // 退出登录
    handleOutLogin() {
      Dialog.confirm({
        message: "您确定要退出登录？",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonColor: globalStyle.themeColor,
        cancelButtonColor: globalStyle.grayColor,
      })
        .then(() => {
          logout({}).then((res) => {
            removeToken();
            removeRefreshToken();
            this.$store.commit("user/SET_USERMSG", "");
            localStorage.clear();
            this.$router.push({ path: "/login" });
          });
        })
        .catch(() => {});
    },

    // 跳转修改密码
    handleChangePassword() {
      this.$router.push({
        path: "/changePassword",
      });
    },

    handleJumpTicketVerify() {
      this.$router.push({
        path: "/ticketNum",
      });
    },

    handleHistory() {
      this.$router.push({
        path: "/verifyRecord",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../css.scss";
.main ::v-deep .van-pull-refresh {
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: 100%;
  background-color: $--bg-color;
  .opeBox {
    width: 345px;
    height: 160px;
    margin-top: 15px;
    margin: 64px auto 0;
    .topCancel {
      height: 85px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .cancelBox {
        width: 165px;
        height: 100%;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        img {
          display: block;
          width: 30px;
          height: 30px;
          margin: 10px auto 0;
        }
        div {
          font-size: 14px;
          color: $--font-color;
          font-weight: 500;
          text-align: center;
        }
      }
    }
    .botCancel {
      width: 100%;
      height: 60px;
      margin-top: 15px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.01);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
      }
      span {
        font-size: 14px;
        color: $--font-color;
        height: 100%;
        line-height: 60px;
        margin-left: 10px;
        font-weight: 500;
      }
    }
  }
  .bgBox {
    width: 100%;
    height: 120px;
    background-color: $--theme-color;
    position: relative;
    .msgBox {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      width: 345px;
      height: 120px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      padding: 15px;
      .topBox,
      .botBox {
        height: 45px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .topBox {
        .leftBox {
          height: 100%;
          line-height: 45px;
          font-size: 18px;
          color: $--font-color;
          font-weight: 700;
        }
        img {
          width: 24px;
          height: 24px;
          margin-top: 10px;
        }
      }
      .botBox {
        .leftBox {
          height: 100%;
          line-height: 45px;
          font-size: 14px;
          color: $--gray-color;
        }
        .rBox {
          height: 100%;
          line-height: 45px;
          font-size: 12px;
          color: $--theme-color;
        }
      }
    }
  }
}
</style>
